import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      flexDirection: "column"
    },
    image: {
      width: "50%",
      padding: 10
    },
    centerImage: {
      alignItems: "center",
      flexGrow: 1
    },
    text: {
      width: "100%",
      backgroundColor: "#f0f0f0",
      paddingHorizontal: 50,
      paddingVertical: 30,
      color: "#212121"
    }
  });
  
  const MyDocument = (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.centerImage}>
          <Image style={styles.image} src="/assets/img/logo.png" />
        </View>
        <Text style={styles.text}>
          PSPDFKit GmbH is the leading cross-platform SDK solution for integrating
          PDF support on all major platforms: iOS, Android, Windows, macOS, and on
          Web (both server-based and standalone via WebAssembly).
        </Text>
        <Text style={styles.text}>
          Our solutions enable customers to seamlessly add powerful PDF viewing,
          editing, annotating, and form filling/signing into their app in under 15
          minutes, saving months of development time and expense.
        </Text>
        <Text style={styles.text}>
          Learn more at <Link src="https://pspdfkit.com/">pspdfkit.com</Link>
        </Text>
      </Page>
    </Document>
  );



export default MyDocument;