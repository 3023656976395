import React, { Component } from "react";
import { CompanyBranchLocalInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col } from "antd";
import { CityInterface } from "../../_Interfaces/city";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";

type State = {
    saveLoading: boolean;
    cities: Array<CityInterface>;
};

type Props = {
    companyBranchId: number;
    companyBranchLocal: CompanyBranchLocalInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
        cities: []
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 24
    };

    saveItem = async (companyBranchLocal: CompanyBranchLocalInterface) => {
        this.setState({ saveLoading: true });
        companyBranchLocal.companyBranchId = this.props.companyBranchId;

        if (this.props.companyBranchLocal.id)
            await API.put(`/companyBranchLocal/${this.props.companyBranchLocal.id}`, companyBranchLocal);
        else
            await API.post('/companyBranchLocal', companyBranchLocal);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };

    onCancel = () => this.props.hideModalForm();
    onRemove = () => this.formRef.current?.setFieldsValue({ logo: null });

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    render() {
        const { isVisible, companyBranchLocal } = this.props;
        const { saveLoading } = this.state;

        return (
            <Modal
                width={1000}
                centered
                title={companyBranchLocal.id ? "Editar área" : "Nova área"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" initialValues={companyBranchLocal} onFinish={this.onOk}>
                    <Row>
                        <Col {...this.propsForSmallScreen}>
                            <Form.Item name="name" label="Nome da área" rules={[{ required: true, message: "Preencha o nome da área" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default NewItem;
