const NonAuthRoutes = {
    login: "/login",
    default: "/"
};

const AuthRoutes = {
    dashboard: "/dashboard",
    company: "/company"
};

export {
    NonAuthRoutes, AuthRoutes
}