import { message } from "antd";
import { translate as t } from "../translator/translate";
import Authentication from "./Authentication";

export const JSONHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Credentials': 'same-origin'
};

class Request {
    async get(url: any, headers = JSONHeaders) {
        this.asyncCall(url, "GET", null, headers);
    }

    async post(url: any, dataObject: any = null, headers = JSONHeaders) {
        this.asyncCall(url, "POST", dataObject, headers);
    }

    async put(url: any, headers = JSONHeaders) {
        this.asyncCall(url, "PUT", null, headers);
    }

    async delete(url: any, dataObject: any = null, headers = JSONHeaders) {
        this.asyncCall(url, "DELETE", dataObject, headers);
    }

    async asyncCall(url: string, methodType: string, dataObject: any = null, headers: any) {
        const res = await fetch(url, {
            body: dataObject !== null ? JSON.stringify(dataObject) : null,
            headers: headers,
            method: methodType
        });

        // Status=401
        if (res.status === 302) {
            message.error(t("Você não possui acesso para executar esta operação."));
            Authentication.logout();
            window.location.href = "/";
        }

        // Status=401
        if (res.status === 401) {
            message.error(t("Você não possui acesso para executar esta operação."));
            Authentication.logout();
            window.location.href = "/";
        }

        // Status=402
        if (res.status === 402) {
            window.location.href = "/unauthorized";
        }

        // Status=404
        if (res.status === 404) {
            message.error(t("A rota desejada não existe."));
            return []
        }


        try {
            const json = await res.json()

            if (res.status === 500) {
                message.error(t(json.message));
                return [];
            }

            else if (res.status === 422) {
                message.info(t(json.message));
                return [];
            } else if (json === "planRequired" && url !== 'login')
                window.location.href = "/plan";

            return json
        }
        catch (ex) {
            if (process.env.NODE_ENV === "development")
                console.log(res, ex);
        }
    }
};

export default new Request();