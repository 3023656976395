import React from 'react'
import Form from './Form';
import API from '../../../lib/API';
import { Table, Divider, Popconfirm, Button } from 'antd';
import { NormInterface } from './_interfaces';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Content from '../../_Layout/Content';

type State = {
    norms: Array<NormInterface>,
    filteredNorm: Array<NormInterface>,
    norm: NormInterface | {},
    isVisible: Boolean,
    loading: Boolean
};

type Props = {
    history: any
}

export default class NormIndex extends React.Component<Props, State> {

    state = {
        norm: {},
        norms: [],
        filteredNorm: [],
        loading: false,
        isVisible: false
    };

    getNorm = async () => this.setState({ norms: await API.get('/norm'), loading: false });

    hideModalForm = (fetchNorm: boolean = false) => {
        this.setState({ isVisible: false, norm: {} });

        if (fetchNorm)
            this.setState({ isVisible: false, norm: {}, loading: true }, () => this.getNorm());
    };

    onDelete = async (norm: NormInterface) => {
        this.setState({ loading: true });
        await API.delete(`/norm/${norm.id}`);
        this.getNorm();
    };

    componentDidMount = async () => {
        this.setState({ loading: true });
        await this.getNorm();
    };

    render() {
        const { norms, filteredNorm, norm, loading, isVisible } = this.state;

        return (
            <Content crumbs={[{ name: "Normas" }]}>
                <Button className="right" type="primary" style={{ marginBottom: 16 }}
                    onClick={() => this.setState({ isVisible: true, norm: {} })}>
                    Cadastrar
                </Button>
                <Table
                    style={{ cursor: "pointer" }}
                    loading={loading}
                    columns={this.columns}
                    dataSource={filteredNorm.length > 0 ? filteredNorm : norms} />
                <Form isVisible={isVisible} hideModalForm={this.hideModalForm} norm={norm} />
            </Content>);
    }

    columns = [
        {
            title: "Número",
            dataIndex: 'number',
            key: 'number',

        },
        {
            title: "Revisão",
            dataIndex: 'revision',
            key: 'revision',

        },
        {
            key: "action",
            render: (_: any, norm: NormInterface) => (
                <>
                    <EditOutlined onClick={() => this.setState({ isVisible: true, norm: norm })} />
                    <Divider type="vertical" />
                    <Popconfirm title={"Deseja remover?"}
                        placement="top"
                        onConfirm={() => this.onDelete(norm)}
                        cancelText={"Cancelar"}
                        okText={"Deletar"}
                        okType={"danger"}>
                        <DeleteOutlined />
                    </Popconfirm>
                </>)
        }
    ];

}