import React, { Component } from 'react';
import { Layout, Menu } from "antd";
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { BankOutlined, HomeOutlined, SolutionOutlined, ToolOutlined } from '@ant-design/icons';

type State = {
    collapsed: Boolean
};
export default class Sider extends Component<any, State> {

    state = {
        collapsed: false,
    };

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    render() {
        return (
            <Layout.Sider collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
                style={{ minHeight: '100vh' }}>
                <Logo />
                <Menu
                    style={{ marginTop: 30 }}
                    theme="dark"
                    mode="inline"
                >
                    <Menu.Item key="1">
                        <HomeOutlined />
                        <span>Home</span>
                        <Link to="/dashboard" />
                    </Menu.Item>
                    <Menu.Item key="2">
                        <BankOutlined />
                        <span>Clientes</span>
                        <Link to="/company" />
                    </Menu.Item>
                    <Menu.Item key="3">
                        <SolutionOutlined />
                        <span>Laudos</span>
                        <Link to="/report" />
                    </Menu.Item>
                    <Menu.SubMenu key="4" title="Configurações" icon={<ToolOutlined />}>
                        <Menu.Item><Link to="/management/instrumentStandard">Padrões de calibração</Link></Menu.Item>
                        <Menu.Item><Link to="/management/norm">Procedimentos</Link></Menu.Item>
                        <Menu.Item><Link to="/management/equipment">Equipamentos</Link></Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            </Layout.Sider >
        );
    }
}