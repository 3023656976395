import React, { Component } from "react";
import { Form, Input, Modal, Row, Col, Select, Space, Button, Divider, Upload, message } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";
import { AssessmentInterface } from "./_interfaces";

type State = {
    saveLoading: boolean;
};

type Props = {
    companyBranchEquipmentId: number;
    assessment: AssessmentInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
    };

    private formRef = React.createRef<FormInstance>();

    onCancel = () => this.props.hideModalForm();

    saveItem = async (assessment: AssessmentInterface) => {
        this.setState({ saveLoading: true });
        assessment.companyBranchEquipmentId = this.props.companyBranchEquipmentId;

        if (this.props.assessment.id)
            await API.put(`/assessment/${this.props.assessment.id}`, assessment);
        else
            await API.post('/assessment', assessment);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    }

    render() {
        const { isVisible, assessment } = this.props;
        const { saveLoading } = this.state;

        return (
            <Modal
                centered
                title={assessment.id ? "Editar avaliação" : "Nova avaliação"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" initialValues={assessment} onFinish={this.onOk}>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="orderOfService" label="Ordem de Serviço" rules={[{ required: true, message: "Digite o número da OS" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal >
        );
    }
}

export default NewItem;