import React, { Component } from "react";
import { InstrumentInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../../lib/API";

type State = {
    saveLoading: boolean;
};

type Props = {
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
    };

    private formRef = React.createRef<FormInstance>();

    saveItem = async (instrument: InstrumentInterface) => {
        this.setState({ saveLoading: true });
        await API.post('/instrument', instrument);
        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };

    onCancel = () => this.props.hideModalForm();

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    render() {
        const { isVisible } = this.props;
        const { saveLoading } = this.state;

        return (
            <Modal
                centered
                title={"Novo instrumento"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" onFinish={this.onOk}>
                    <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Digite o nome" }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default NewItem;
