import React, { Component } from "react";
import { ReportInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col, Select, Checkbox, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import API from "../../../../lib/API";

type State = {
    saveLoading: boolean;
};

type Props = {
    companyBranchEquipmentId: number;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewReport extends Component<Props, State> {
    state: State = {
        saveLoading: false,
    };

    private formRef = React.createRef<FormInstance>();

    saveItem = async (report: ReportInterface) => {
        this.setState({ saveLoading: true });
        report.companyBranchEquipmentId = this.props.companyBranchEquipmentId;
        await API.post('/report', report);
        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };

    onCancel = () => this.props.hideModalForm();

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    render() {
        const { isVisible } = this.props;
        const { saveLoading } = this.state;

        return (
            <Modal
                centered
                title={"Novo relatório"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" onFinish={this.onOk}>
                    <Form.Item name="title" label="Título" rules={[{ required: true, message: "Digite o título" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item valuePropName="checked" name="hasEnvironmentalConditions">
                        <Checkbox>Exibir condições ambientais?</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName="checked" name="haveEquipmentStandards">
                        <Checkbox>Exibir padrões de calibração?</Checkbox>
                    </Form.Item>
                    <Form.Item valuePropName="checked" name="hasNormAndProcedures">
                        <Checkbox>Exibir normas e procedimentos?</Checkbox>
                    </Form.Item>
                    <label><b>Campos para o resultado</b></label>
                    <Form.List name="reportField">
                        {(reportFields, { add, remove }) => (
                            <>
                                {reportFields.map(({ key, name, ...restField }) => (
                                    <Row key={key}>
                                        <Col lg={8}>
                                            <Form.Item
                                                label="Nome do campo"
                                                name={[name, 'title']}
                                                fieldKey={[key, 'title']}
                                                rules={[{ required: true, message: 'Digite o título do campo' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item
                                                label="Tipo do campo"
                                                name={[name, 'type']}
                                                fieldKey={[key, 'type']}
                                                rules={[{ required: true, message: 'Selecione o tipo do campo' }]}
                                            >
                                                <Select>
                                                    <Select.Option value="text">Texto</Select.Option>
                                                    <Select.Option value="select">Lista de opções</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <MinusCircleOutlined style={{ paddingTop: 40 }} onClick={() => remove(name)} />
                                    </Row>
                                ))}
                                <Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Adicionar campo
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        );
    }
}

export default NewReport;
