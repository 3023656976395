import React from 'react'
import API from '../../../lib/API';
import { CompanyInterface } from './_interfaces';
import Content from '../../_Layout/Content';
import CompanyBranchIndex from '../CompanyBranch/_Index';

type State = {
  company: CompanyInterface;
  loading: Boolean;
};

type Props = {
  history: any;
  match: any;
}

export default class CompanyIndex extends React.Component<Props, State> {

  state = {
    company: {} as CompanyInterface,
    loading: true,
  };

  getCompany = async () => this.setState({
    company: await API.get(`/company/${this.props.match.params.companyId}`),
    loading: false
  });

  componentDidMount = async () => {
    this.getCompany();
  };

  crumbs = () => {
    const { company } = this.state;
    return company.id ? [{ name: company.name }, { name: "Unidades" }] : [{ name: "Carregando..." }];
  }

  render() {
    return (
      <Content crumbs={this.crumbs()} >
        <CompanyBranchIndex {...this.props} />
      </Content>);
  }

}