import React, { Component } from "react";
import { ReportFieldOptionInterface } from "./_interfacesOption";
import { Form, Input, Modal } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../../lib/API";

type State = {
    saveLoading: boolean;
};

type Props = {
    reportFieldId: number;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
    };

    private formRef = React.createRef<FormInstance>();

    saveItem = async (reportFieldOption: ReportFieldOptionInterface) => {
        this.setState({ saveLoading: true });
        reportFieldOption.reportFieldId = this.props.reportFieldId;

        await API.post('/reportFieldOption', reportFieldOption);
        this.setState({ saveLoading: false }, () => this.props.hideModalForm(this.props.reportFieldId));
    };

    onCancel = () => this.props.hideModalForm(this.props.reportFieldId);

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    render() {
        const { isVisible } = this.props;
        const { saveLoading } = this.state;

        return (
            <Modal
                centered
                title={"Nova opção"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" onFinish={this.onOk}>
                    <Form.Item name="name" label="Descrição" rules={[{ required: true, message: "Digite a descrição" }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default NewItem;
