import React from 'react'
import API from '../../../lib/API';
import Content from '../../_Layout/Content';
import { CompanyBranchInterface } from './_interfaces';
import CompanyBranchLocalIndex from '../CompanyBranchLocal/_Index';

type State = {
  companyBranch: CompanyBranchInterface;
  loading: Boolean;
};

type Props = {
  history: any;
  match: any;
}

export default class CompanyBranchView extends React.Component<Props, State> {

  state = {
    companyBranch: {} as CompanyBranchInterface,
    loading: true,
  };

  getCompany = async () => this.setState({
    companyBranch: await API.get(`/companyBranch/${this.props.match.params.companyBranchId}`),
    loading: false
  });

  componentDidMount = async () => {
    this.getCompany();
  };

  crumbs = () => {
    const { companyBranch } = this.state;
    return companyBranch.id ? [{ name: companyBranch.company.name }, { name: "Unidades" }, { name: companyBranch.observation != "" ? companyBranch.city.name + " - " + companyBranch.observation : companyBranch.city.name }] : [{ name: "Carregando..." }];
  }

  render() {
    return (
      <Content crumbs={this.crumbs()} >
        <CompanyBranchLocalIndex {...this.props} />
      </Content>);
  }

}