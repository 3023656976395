import React, { Component } from "react";
import { Form, Input, Modal, Row, Col, Select, Space, Button, Divider, Upload, message } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";
import { EquipmentInterface } from "../../Management/Equipment/_interfaces";
import { CompanyBranchEquipmentInterface } from "./_interfaces";

type State = {
    equipments: Array<EquipmentInterface>;
    equipemntsInCompanyBranch: Array<CompanyBranchEquipmentInterface>;
    saveLoading: boolean;
};

type Props = {
    companyBranchLocalId: number;
    companyBranchEquipment: CompanyBranchEquipmentInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        equipments: [],
        equipemntsInCompanyBranch: [],
        saveLoading: false,
    };

    private formRef = React.createRef<FormInstance>();

    onCancel = () => this.props.hideModalForm();

    fetcOptions = async () => {
        this.setState({
            equipments: await API.get('/equipment'),
            equipemntsInCompanyBranch: await API.get(`/companyBranchEquipment/all/${this.props.companyBranchLocalId}`),
        })
    };

    saveItem = async (companyBranchEquipment: CompanyBranchEquipmentInterface) => {
        this.setState({ saveLoading: true });
        companyBranchEquipment.companyBranchLocalId = this.props.companyBranchLocalId;

        if (this.props.companyBranchEquipment.id)
            await API.put(`/companyBranchEquipment/${this.props.companyBranchEquipment.id}`, companyBranchEquipment);
        else
            await API.post('/companyBranchEquipment', companyBranchEquipment);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    }

    componentDidMount = async () => this.fetcOptions();
    componentWillReceiveProps = async () => this.fetcOptions();

    render() {
        const { isVisible, companyBranchEquipment } = this.props;
        const { equipments, equipemntsInCompanyBranch, saveLoading } = this.state;

        return (
            <Modal
                centered
                title={companyBranchEquipment.id ? "Editar equipamento" : "Novo equipamento"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" initialValues={companyBranchEquipment} onFinish={this.onOk}>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="equipmentId" label="Equipamento" rules={[{ required: true, message: "Selecione um equipamento" }]}>
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {equipments.map((equipment, key) => <Select.Option
                                        key={key}
                                        value={equipment.id}
                                        disabled={equipemntsInCompanyBranch.some(equipmentInCompanyBranch => equipmentInCompanyBranch.equipmentId == equipment.id)}>
                                        {equipment.name}
                                    </Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal >
        );
    }
}

export default NewItem;
