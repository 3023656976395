import React from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";

// routes
import { AuthRoutes, NonAuthRoutes } from "./_Routes/Routes";
import ProtectedRoutes from "./_Routes/ProtectedRoutes";

// components
import Dashboard from "./Dashboard/Dashboard";
import ManagerIndex from "./Manager/_Index";
import Login from "./Login/Login";
import Authentication from "../lib/Authentication";
import InstrumentStandardIndex from "./Management/InstrumentStandard/_Index";
import NormIndex from "./Management/Norm/_Index";
import AssessmentIndex from "./Assessment/Index";
import Sider from "./_Layout/Sider";
import Header from "./_Layout/Header";
import EquipmentIndex from "./Management/Equipment/_Index";
import CompanyIndex from "./Manager/Company/_Index";
import CompanyBranchView from "./Manager/CompanyBranch/View";
import ManagerAssessmentIndex from "./Manager/Assessment/_Index";
import CompanyBranchEquipmentView from "./Manager/CompanyBranchEquipment/View";
import ReportIndex from "./Report/_Index";


const config = {
  locale: ptBR
};

// const url = window.location.origin;
// if (!url.includes('localhost') && !url.includes('https'))
//   document.location.href = `https:${url.split(':')[1]}`

const App = () => {

  function doLogout() {
    Authentication.logout();
    document.location.href = "/";
  }

  return (
    <ConfigProvider {...config}>
      <BrowserRouter>
        <Switch>
          <Route component={Login} path={NonAuthRoutes.login} exact />

          <ProtectedRoutes>
            <Layout>
              {/* <Layout.Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1">
                    <Link to="/dashboard">Home</Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/company">Empresas</Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to="/management">Padrões e Referências</Link>
                  </Menu.Item>
                  <Menu.Item key="10" onClick={doLogout}>Logout</Menu.Item>
                </Menu>
              </Layout.Header> */}
              <Sider />
              <Layout className="site-layout">
                <Header />
                <Layout.Content style={{ margin: '10px 10px 0' }}>
                  <div style={{ padding: '20px 0px 20px 20px', minHeight: 360 }}>
                    <Switch>
                      <Route component={Dashboard} path={AuthRoutes.dashboard} exact />

                      <Route component={NormIndex} path={'/management/norm'} exact />
                      <Route component={EquipmentIndex} path={'/management/equipment'} exact />
                      <Route component={InstrumentStandardIndex} path={'/management/instrumentStandard'} exact />

                      <Route component={ManagerIndex} path={'/company'} exact />
                      <Route component={CompanyIndex} path={'/company/:companyId'} exact />
                      <Route component={CompanyBranchView} path={'/company/:companyId/branch/:companyBranchId'} exact />
                      <Route component={CompanyBranchEquipmentView} path={'/company/:companyId/branch/:companyBranchId/equipment/:companyBranchEquipmentId'} exact />

                      <Route component={AssessmentIndex} path={'/assessment/:assessmentId'} exact />

                      <Route component={ReportIndex} path={'/report'} exact />
                    </Switch>
                  </div>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center' }}>SAGA ©2021</Layout.Footer>
              </Layout>
            </Layout>
          </ProtectedRoutes>

        </Switch>
      </BrowserRouter>
    </ConfigProvider >
  );
};

export default App;
