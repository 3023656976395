import React from 'react';
import ReactDOM from 'react-dom';
import Content from '../_Layout/Content';
import { Row, Col, Layout, Menu, Checkbox, Steps, Form, Input, Card, Select, Button, FormInstance, Modal } from 'antd';
import { PlusOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { AssessmentInterface } from '../Manager/Assessment/_interfaces';
import API from '../../lib/API';
import moment from 'moment';
import { EquipmentInterface } from '../Management/Equipment/_interfaces';
import FormOption from '../Management/Equipment/Report/FormOption';
import { ReportFieldOptionInterface } from '../Management/Equipment/Report/_interfacesOption';
import MyDocument from '../Report/Default';
import { PDFViewer } from '@react-pdf/renderer';


type State = {
    loading: boolean;
    visible: boolean;
    newOption: boolean;
    reportFieldId: number;
    assessment: AssessmentInterface;
    equipment: EquipmentInterface;
    options: Array<Array<ReportFieldOptionInterface>>;
};

export default class AssessmentIndex extends React.Component<any, State> {
    private formRef = React.createRef<FormInstance>();

    state = {
        loading: true,
        visible: false,
        newOption: false,
        reportFieldId: 0,
        assessment: {} as AssessmentInterface,
        equipment: {} as EquipmentInterface,
        options: []
    }

    fetchCertification = async () => {
        this.setState({ loading: true });

        const assessmentId = this.props.match.params.assessmentId;
        const assessment: AssessmentInterface = await API.get(`/assessment/find/${assessmentId}`);
        const equipment = await API.get(`/equipment/${assessment.company_branch_equipment.equipmentId}`);

        if (assessment.assessment_report)
            assessment.answers = assessment.assessment_report

        this.setState({ assessment, equipment, loading: false });
    }

    fetchOptionByField = async (reportFieldId: number = 0) => {
        let options: any = this.state.options;

        if (reportFieldId === 0) {
            const assessment: AssessmentInterface = await API.get(`/assessment/find/${this.props.match.params.assessmentId}`);
            assessment.company_branch_equipment.equipment.report.report_field_relationship.forEach(async (field: any) => {
                if (field.type === "select")
                    options[field.id] = await API.get(`/reportFieldOption/${field.id}`);
            });
        } else {
            options[reportFieldId] = await API.get(`/reportFieldOption/${reportFieldId}`);
        }

        this.setState({ options });
    }

    hideModalForm = (reportFieldId: number) => this.setState({ newOption: false }, () => this.fetchOptionByField(reportFieldId));

    saveItem = async (assessment: AssessmentInterface) => {
        this.setState({ loading: true });

        await API.put(`/assessment/${this.props.match.params.assessmentId}`, assessment);

        this.fetchCertification();
    };

    generatePdf = async () => {
        // await API.post(`/assessment/generatePdf/${this.props.match.params.assessmentId}`);
        this.setState({visible: true}, () => {
            ReactDOM.render(
                <PDFViewer>{MyDocument}</PDFViewer>,
                document.getElementById('pdf'),
            );
        })

    }

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    componentDidMount = async () => {
        await this.fetchOptionByField();
        this.fetchCertification();
    };

    render() {

        const { assessment, options, newOption, reportFieldId, loading, visible } = this.state;

        return <Content crumbs={[]}>
            {/* <Layout className="site-layout" style={{ padding: '24px 0' }}>

                <Layout.Sider className="site-layout" width={370} style={{ background: "transparent" }}>
                    <div style={{ position: "fixed" }}>
                        <h4 style={{ fontSize: 20 }}>Relatório {assessment.id} - {assessment.orderOfService}</h4>
                        <Steps direction="vertical" current={0}>
                            <Steps.Step status="finish" title="Etapa 1" />
                            <Steps.Step status="wait" title="Etapa 2" />
                        </Steps>
                    </div>
                </Layout.Sider>
                <Layout.Content style={{ padding: '0 50px', minHeight: 280 }}> */}

            <Card title={`Relatório ${assessment.id} - ${assessment.orderOfService}`} loading={loading}>
                <Form ref={this.formRef} layout="vertical" onFinish={this.onOk} initialValues={assessment}>
                    <h4>Certificação</h4>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Data de Realização"
                                name="endDate"
                                rules={[{ required: true, message: 'Insira a data de realização' }]}
                            >
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="certificationType"
                                rules={[{ required: true, message: "Selecione uma categoria" }]}
                                label="Tipo de Certificação">
                                <Select>
                                    <Select.Option value="restest">Reteste</Select.Option>
                                    <Select.Option value="inspetion">Inspeção</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {assessment.company_branch_equipment && assessment.company_branch_equipment.equipment.report.hasEnvironmentalConditions
                        ?
                        <>
                            <h4>Condições ambientais</h4>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        label="Temperatura"
                                        name="temperature"
                                        rules={[{ required: true, message: 'Digite a temperatura' }]}
                                    >
                                        <Input suffix="ºC" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Umidade"
                                        name="humidity"
                                        rules={[{ required: true, message: 'Digite a umidade' }]}
                                    >
                                        <Input suffix="%" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                        : null}

                    <h4>Resultado</h4>
                    <Form.List name="answers">
                        {(answers, { add, remove }) => (
                            <>
                                {answers.map(({ key, name, ...restField }) => (
                                    <Row key={key}>
                                        {assessment.company_branch_equipment.equipment.report.report_field_relationship.map((field: any) =>
                                            <Col span={3}>
                                                <Form.Item
                                                    initialValue={
                                                        assessment && assessment.answers.length > 0
                                                            ? assessment.answers[key]
                                                                ? assessment.answers[key].answers.find((a: any) => a.reportFieldId == field.id).answer
                                                                : null
                                                            : null}
                                                    label={
                                                        field.type === "text"
                                                            ? field.title
                                                            : <span>{field.title} <PlusCircleOutlined onClick={() => this.setState({ newOption: true, reportFieldId: field.id })} /> </span>
                                                    }
                                                    name={[name, `answer${field.id}`]}
                                                    fieldKey={[key, `answer${field.id}`]}
                                                    rules={[{ required: true, message: 'Preencha o campo' }]}>
                                                    {
                                                        field.type === "text"
                                                            ? <Input />
                                                            : <Select>
                                                                {options.map((option: Array<ReportFieldOptionInterface>) => option.map((opt, key) =>
                                                                    opt.reportFieldId === field.id
                                                                        ? <Select.Option key={key} value={opt.id.toString()}>{opt.name}</Select.Option>
                                                                        : null
                                                                ))}
                                                            </Select>
                                                    }
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col>
                                            <MinusCircleOutlined style={{ paddingTop: 40 }} onClick={() => remove(name)} />
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Adicionar campo
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </>
                        )}
                    </Form.List>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <FormOption isVisible={newOption} hideModalForm={this.hideModalForm} reportFieldId={reportFieldId} />
            </Card>
            {/* </Layout.Content>
            </Layout> */}
            <Modal visible={this.state.visible}         width={1000}
>
            <div id="pdf" style={{width: "100%"}} ></div>
            </Modal>
            <Button onClick={this.generatePdf}>TESTE</Button>
        </Content >
    }
}
