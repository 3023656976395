import React, { Component } from "react";
import API from '../../../lib/API';
import { Avatar, Card, Col, Input, List, Row } from 'antd';
import { CompanyBranchInterface } from "../../Manager/CompanyBranch/_interfaces";
import { CompanyBranchLocalInterface } from "../../Manager/CompanyBranchLocal/_interfaces";

type State = {
    loading: boolean;
    companyBranchLocals: Array<CompanyBranchLocalInterface>;
    companyBranchLocalsFiltered: Array<CompanyBranchLocalInterface>;
};

type Props = {
    next: Function;
    companyBranch: CompanyBranchInterface;
};

export default class CompanyBranchLocal extends Component<Props, State> {

    state: State = {
        companyBranchLocals: [] as Array<CompanyBranchLocalInterface>,
        companyBranchLocalsFiltered: [] as Array<CompanyBranchLocalInterface>,
        loading: true
    };

    getCompanyBranchLocals = async () => this.setState({ companyBranchLocals: await API.get(`/companyBranchLocal/all/${this.props.companyBranch.id}`), loading: false });

    searchCompanyBranchLocal = (filter: String) => {
        const { companyBranchLocals } = this.state;
        const companyBranchLocalsFiltered = companyBranchLocals.filter((companyBranchLocal: CompanyBranchLocalInterface) =>
            companyBranchLocal.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase())
        );

        this.setState({ companyBranchLocalsFiltered });
    };

    componentDidMount = async () => {
        this.getCompanyBranchLocals();
    };

    render() {
        const { companyBranchLocals, companyBranchLocalsFiltered, loading } = this.state;
        const { companyBranch } = this.props;

        return (
            <Card title="Selecione a área">
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <Input.Search
                            placeholder="Digite o nome da unidade"
                            allowClear
                            enterButton
                            size="large"
                            onSearch={this.searchCompanyBranchLocal}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <List
                            loading={loading}
                            style={{ cursor: "pointer" }}
                            itemLayout="horizontal"
                            dataSource={companyBranchLocalsFiltered.length > 0 ? companyBranchLocalsFiltered : companyBranchLocals}
                            renderItem={(companyBranchLocal: CompanyBranchLocalInterface) => (
                                <List.Item onClick={() => this.props.next({ companyBranchLocal }, companyBranchLocal.name)}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={companyBranch.company.logo} />}
                                        title={companyBranchLocal.name}
                                        // description={`Possui ${companyBranchLocal.company_branch_local.length} área(s)`}
                                    />
                                </List.Item>
                            )}
                        />

                    </Col>
                </Row>
            </Card>
        );
    }
}