import React from 'react';
import Authentication from '../../lib/Authentication';

// @ts-ignore
import { Redirect } from "react-router-dom"
import { NonAuthRoutes } from "./Routes";

const ProtectedRoutes = (props: any): JSX.Element => {
    const isLoggedIn = Authentication.isAuthenticated();
    if (!isLoggedIn)
        return <Redirect to={NonAuthRoutes.login} />;

    return props.children;
};

export default ProtectedRoutes;