import React, { Component } from 'react';
import { DownOutlined } from "@ant-design/icons";
import Authentication from "../../lib/Authentication";
import { Layout, Menu, Dropdown } from "antd";

class Header extends Component {


    doLogout() {
        Authentication.logout();
        document.location.href = "/";
    }

    render() {
        const menu = (
            <Menu>
                <Menu.Item onClick={this.doLogout}>
                    Logout
                </Menu.Item>
            </Menu>
        );

        return (
            <Layout.Header className="site-layout-background" style={{ background: '#fff' }}>
                <div className="header-info-container">
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: "black" }}>
                            {Authentication.getUserName()} <DownOutlined />
                        </a>
                    </Dropdown>
                </div>
            </Layout.Header >
        );
    }
}

export default Header;
