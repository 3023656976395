import React, { Component } from "react";
import API from '../../../lib/API';
import { Card, Col, Row, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ClientInterface } from "../../_Interfaces/client";
import { ModuleClientInterface } from "../../_Interfaces/module_client";
import Meta from "antd/lib/card/Meta";

type State = {
    loading: boolean;
    client: ClientInterface;
};

type Props = {
    next: Function;
};

export default class Norm extends Component<Props, State> {

    state: State = {
        client: {} as ClientInterface,
        loading: true
    };

    getModules = async () => this.setState({ client: await API.get(`/my/module`), loading: false });

    get renderNorms() {
        const { client } = this.state;

        return client.module_client
            ? client.module_client.map((moduleClient: ModuleClientInterface, key: Number) =>
                <Col key={key.toString()} span={6}>
                    <Card
                        hoverable
                        onClick={() => this.props.next({ module: moduleClient.module}, moduleClient.module.name )}
                        cover={<img alt="example" width="100%" src="http://hecato.com/arquivos/LoginID_224/Padrao/vasos-caldeiras-pizzatto-engenharia_8594.jpg" />}
                    >
                        <Meta title={moduleClient.module.name} description="Iniciar" />
                    </Card>
                </Col>
            )
            : <Spin />;
    }

    componentDidMount = async () => {
        this.getModules();
    };

    render() {
        return (
            <Row style={{ justifyContent: "center" }}>
                {this.renderNorms}
            </Row>
        );
    }


}
