import React from 'react'
import Content from '../_Layout/Content';
import { translate as t } from "../../translator/translate";
import Authentication from '../../lib/Authentication';


export default class Dashboard extends React.Component {

  render() {

    return <Content crumbs={[{ name: t("Dashboard") }]}>
      Bem-vindo, {Authentication.getUserName()}!
    </Content>
  }
}
