import React from 'react'
import Form from './Company/Form';
import API from '../../lib/API';
import { Avatar, Table, Button, Divider } from 'antd';
import { CompanyInterface } from './Company/_interfaces';
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import Content from '../_Layout/Content';

type State = {
    companies: Array<CompanyInterface>,
    company: CompanyInterface | {},
    isVisible: Boolean,
    loading: Boolean
};

type Props = {
    history: any
}

export default class ManagerIndex extends React.Component<Props, State> {

    state = {
        company: {},
        companies: [],
        loading: false,
        isVisible: false
    };

    getCompanies = async () => this.setState({ companies: await API.get('/company'), loading: false });

    hideModalForm = (fetchCompanies: boolean = false) => {
        this.setState({ isVisible: false, company: {} });

        if (fetchCompanies)
            this.setState({ isVisible: false, company: {}, loading: true }, () => this.getCompanies());
    };

    componentDidMount = async () => {
        this.setState({ loading: true });
        await this.getCompanies();
    };

    render() {
        const { companies, company, loading, isVisible } = this.state;

        return (
            <Content crumbs={[{ name: "Empresas" }]}>
                <Button className="right" type="primary" style={{ marginBottom: 20, marginTop: -30 }}
                    onClick={() => this.setState({ isVisible: true, company: {} })}>
                    Cadastrar
                </Button>
                <Table
                    loading={loading}
                    columns={this.columns}
                    dataSource={companies} />
                <Form isVisible={isVisible} hideModalForm={this.hideModalForm} company={company} />
            </Content>);
    }

    columns = [
        {
            dataIndex: 'name',
            title: 'Nome',
            key: 'name',
            render: (_: any, company: CompanyInterface) => (
                <span>
                    <Avatar src={company.logo ? company.logo : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />
                    {" " + company.name}
                </span>
            )
        },
        {
            key: "action",
            render: (_: any, company: CompanyInterface) => (
                <>
                    <EyeOutlined onClick={() => { this.props.history.push(`/company/${company.id}`) }} />
                    <Divider type='vertical' />
                    <EditOutlined onClick={() => this.setState({ isVisible: true, company: company })} />
                </>
            )
        }
    ];

}