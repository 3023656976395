import React, { Component } from "react";
import API from '../../../lib/API';
import { Avatar, Card, Col, Input, List, Row } from 'antd';
import { CompanyBranchInterface } from "../../Manager/CompanyBranch/_interfaces";
import Meta from "antd/lib/card/Meta";
import { CompanyInterface } from "../../Manager/Company/_interfaces";

type State = {
    loading: boolean;
    companyBranchies: Array<CompanyBranchInterface>;
    companyBranchiesFiltered: Array<CompanyBranchInterface>;
};

type Props = {
    next: Function;
    company: CompanyInterface;
};

export default class CompanyBranch extends Component<Props, State> {

    state: State = {
        companyBranchies: [] as Array<CompanyBranchInterface>,
        companyBranchiesFiltered: [] as Array<CompanyBranchInterface>,
        loading: true
    };

    get renderNorms() {
        const { companyBranchies, companyBranchiesFiltered } = this.state;
        let list = companyBranchiesFiltered.length > 0 ? companyBranchiesFiltered : companyBranchies;

        return list.map((companyBranch: CompanyBranchInterface, key: Number) =>
            <Col key={key.toString()} span={3}>
                <Card
                    hoverable
                    onClick={() => this.props.next({ companyBranch })}
                    cover={<img alt="example" width="100%" src={this.props.company.logo} />}
                >
                    <Meta title={companyBranch.city.name} />
                </Card>
            </Col>
        );
    }

    getCompanies = async () => this.setState({ companyBranchies: await API.get(`/companyBranch/all/${this.props.company.id}`), loading: false });

    searchCompanyBranch = (filter: String) => {
        const { companyBranchies } = this.state;
        const companyBranchiesFiltered = companyBranchies.filter((companyBranch: CompanyBranchInterface) =>
            companyBranch.city.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase())
        );

        this.setState({ companyBranchiesFiltered });
    };

    renderCompanyBranchName = (companyBranch: CompanyBranchInterface) =>
        companyBranch.observation != "" ? companyBranch.city.name + " - " + companyBranch.observation : companyBranch.city.name;

    componentDidMount = async () => {
        this.getCompanies();
    };

    render() {
        const { companyBranchies, companyBranchiesFiltered, loading } = this.state;

        return (
            <Card title="Selecione a unidade">
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <Input.Search
                            placeholder="Digite o nome da unidade"
                            allowClear
                            enterButton
                            size="large"
                            onSearch={this.searchCompanyBranch}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <List
                            loading={loading}
                            style={{ cursor: "pointer" }}
                            itemLayout="horizontal"
                            dataSource={companyBranchiesFiltered.length > 0 ? companyBranchiesFiltered : companyBranchies}
                            renderItem={(companyBranch: CompanyBranchInterface) => (
                                <List.Item onClick={() => this.props.next({ companyBranch }, this.renderCompanyBranchName(companyBranch))}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={companyBranch.company.logo} />}
                                        title={this.renderCompanyBranchName(companyBranch)}
                                        description={`Possui ${companyBranch.company_branch_local.length} área(s)`}
                                    />
                                </List.Item>
                            )}
                        />

                    </Col>
                    {/* {this.renderNorms} */}
                </Row>
            </Card>
        );
    }
}