import React, { Component } from "react";
import { CompanyBranchInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";
import { CityInterface } from "../../_Interfaces/city";
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';

type State = {
    saveLoading: boolean;
    cities: Array<CityInterface>;
};

type Props = {
    companyId: number;
    companyBranch: CompanyBranchInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
        cities: []
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 12
    };

    saveItem = async (companyBranch: CompanyBranchInterface) => {
        this.setState({ saveLoading: true });
        companyBranch.companyId = this.props.companyId;

        if (this.props.companyBranch.id)
            await API.put(`/companyBranch/${this.props.companyBranch.id}`, companyBranch);
        else
            await API.post('/companyBranch', companyBranch);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };

    onCancel = () => this.props.hideModalForm();
    onRemove = () => this.formRef.current?.setFieldsValue({ logo: null });

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    componentDidMount = async () => this.setState({ cities: await API.get('/city') });

    render() {
        const { isVisible, companyBranch } = this.props;
        const { saveLoading, cities } = this.state;

        return (
            <Modal
                width={1000}
                centered
                title={companyBranch.id ? "Editar unidade" : "Nova unidade"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" initialValues={companyBranch} onFinish={this.onOk}>
                    <Row>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="cityId" label="Cidade (UF)" rules={[{ required: true, message: "Selecione a cidade" }]}>
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) =>
                                        option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {cities.map((city, key) => <Select.Option key={key} value={city.id}>{city.name} - {city.uf.code}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} {...this.propsForSmallScreen}>
                            <Form.Item name="street" label="Rua" rules={[{ required: true, message: "Digita a rua" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={4} {...this.propsForSmallScreen}>
                            <Form.Item name="number" label="Número">
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} {...this.propsForSmallScreen}>
                            <Form.Item name="complement" label="Complemento">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={10} {...this.propsForSmallScreen}>
                            <Form.Item name="neighborhood" label="Bairro" rules={[{ required: true, message: "Digita o bairro" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item name="cnpj" label="CNPJ">
                                <MaskedInput mask="11.111.111/1111-11" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} {...this.propsForSmallScreen}>
                            <Form.Item name="observation" label="Observação">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default NewItem;
