import React from 'react'
import Form from './Form';
import API from '../../../lib/API';
import { Card, Table, Input, Divider, Button } from 'antd';
import { EquipmentInterface } from './_interfaces';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Popconfirm from 'antd/es/popconfirm';
import Content from '../../_Layout/Content';

type State = {
    equipments: Array<EquipmentInterface>,
    filteredEquipment: Array<EquipmentInterface>,
    equipment: EquipmentInterface,
    isVisible: Boolean,
    loading: Boolean
};

type Props = {
    history: any
}

export default class EquipmentIndex extends React.Component<Props, State> {

    state = {
        equipment: {} as EquipmentInterface,
        equipments: [],
        filteredEquipment: [],
        loading: false,
        isVisible: false
    };

    getEquipment = async () => this.setState({ equipments: await API.get('/equipment'), loading: false });

    hideModalForm = (fetchEquipment: boolean = false) => {
        this.setState({ isVisible: false, equipment: {} as EquipmentInterface });

        if (fetchEquipment)
            this.setState({ isVisible: false, equipment: {} as EquipmentInterface, loading: true }, () => this.getEquipment());
    };

    onDelete = async (equipment: EquipmentInterface) => {
        this.setState({ loading: true });
        await API.delete(`/equipment/${equipment.id}`);
        this.getEquipment();
    };

    componentDidMount = async () => {
        this.setState({ loading: true });
        await this.getEquipment();
    };

    render() {
        const { equipments, filteredEquipment, equipment, loading, isVisible } = this.state;

        return (
            <Content crumbs={[{ name: "Normas" }]}>
                <Button className="right" type="primary" style={{ marginBottom: 16 }}
                    onClick={() => this.setState({ isVisible: true, equipment: {} as EquipmentInterface })}>
                    Cadastrar
                </Button>
                <Table
                    style={{ cursor: "pointer" }}
                    loading={loading}
                    columns={this.columns}
                    dataSource={filteredEquipment.length > 0 ? filteredEquipment : equipments} />
                <Form isVisible={isVisible} hideModalForm={this.hideModalForm} equipment={equipment} />
            </Content>);
    }

    columns = [
        {
            title: "Denominação",
            dataIndex: 'name',
            key: 'name',

        },
        {
            key: "action",
            render: (_: any, equipment: EquipmentInterface) => (
                <>
                    <EditOutlined onClick={() => this.setState({ isVisible: true, equipment: equipment })} />
                    <Divider type="vertical" />
                    <Popconfirm title={"Deseja remover?"}
                        placement="top"
                        onConfirm={() => this.onDelete(equipment)}
                        cancelText={"Cancelar"}
                        okText={"Deletar"}
                        okType={"danger"}>
                        <DeleteOutlined />
                    </Popconfirm>
                </>)
        }
    ];

}