import React, { Component } from "react";
import API from '../../../lib/API';
import { Avatar, Card, Col, Input, List, Row, Button } from 'antd';
import { CompanyBranchLocalInterface } from "../../Manager/CompanyBranchLocal/_interfaces";
import { CompanyBranchEquipmentInterface } from "../../Manager/CompanyBranchEquipment/_interfaces";
import { CompanyInterface } from "../../Manager/Company/_interfaces";
import Form from '../../Manager/CompanyBranchEquipment/Form';


type State = {
    loading: boolean;
    isVisible: boolean;
    companyBranchEquipment: CompanyBranchEquipmentInterface;
    companyBranchEquipments: Array<CompanyBranchEquipmentInterface>;
    companyBranchEquipmentsFiltered: Array<CompanyBranchEquipmentInterface>;
};

type Props = {
    next: Function;
    company: CompanyInterface;
    companyBranchLocal: CompanyBranchLocalInterface;
};

export default class CompanyBranchEquipment extends Component<Props, State> {

    state: State = {
        companyBranchEquipments: [] as Array<CompanyBranchEquipmentInterface>,
        companyBranchEquipmentsFiltered: [] as Array<CompanyBranchEquipmentInterface>,
        loading: true,
        isVisible: false,
        companyBranchEquipment: {} as CompanyBranchEquipmentInterface
    };

    getCompanyBranchEquipments = async () => this.setState({ companyBranchEquipments: await API.get(`/companyBranchEquipment/all/${this.props.companyBranchLocal.id}`), loading: false });

    searchCompanyBranchLocal = (filter: String) => {
        const { companyBranchEquipments } = this.state;
        const companyBranchEquipmentsFiltered = companyBranchEquipments.filter((CompanyBranchEquipment: CompanyBranchEquipmentInterface) =>
            CompanyBranchEquipment.equipment.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase())
        );

        this.setState({ companyBranchEquipmentsFiltered });
    };

    hideModalForm = (fetchCompanies: boolean = false) => {
        this.setState({ isVisible: false, companyBranchEquipment: {} as CompanyBranchEquipmentInterface });

        if (fetchCompanies)
            this.setState({ isVisible: false, companyBranchEquipment: {} as CompanyBranchEquipmentInterface, loading: true }, () => this.getCompanyBranchEquipments());
    };

    componentDidMount = async () => {
        this.getCompanyBranchEquipments();
    };

    render() {
        const { companyBranchEquipment, companyBranchEquipments, companyBranchEquipmentsFiltered, loading, isVisible } = this.state;
        const { company, companyBranchLocal } = this.props;

        return (
            <Card title={
                <div>
                    <span>Selecione o equipamento</span>
                    <Button className="right" type="primary"
                        onClick={() => this.setState({ isVisible: true, companyBranchEquipment: {} as CompanyBranchEquipmentInterface })}>
                        Cadastrar
                    </Button>
                </div>
            }>
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <Input.Search
                            placeholder="Digite o nome do equipamento"
                            allowClear
                            enterButton
                            size="large"
                            onSearch={this.searchCompanyBranchLocal}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <List
                            loading={loading}
                            style={{ cursor: "pointer" }}
                            itemLayout="horizontal"
                            dataSource={companyBranchEquipmentsFiltered.length > 0 ? companyBranchEquipmentsFiltered : companyBranchEquipments}
                            renderItem={(companyBranchEquipment: CompanyBranchEquipmentInterface) => (
                                <List.Item onClick={() => this.props.next({ companyBranchEquipment }, companyBranchEquipment.equipment.name)}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={company.logo} />}
                                        title={companyBranchEquipment.equipment.name}
                                    // description={`Possui ${CompanyBranchEquipment.company_branch_local.length} área(s)`}
                                    />
                                </List.Item>
                            )}
                        />

                    </Col>
                </Row>
                <Form isVisible={isVisible} hideModalForm={this.hideModalForm} companyBranchEquipment={companyBranchEquipment} companyBranchLocalId={companyBranchLocal.id} />
            </Card>
        );
    }
}