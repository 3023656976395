import React, { Component } from "react";
import API from '../../../lib/API';
import { Avatar, Card, Col, Input, List, Row } from 'antd';
import { CompanyInterface } from "../../Manager/Company/_interfaces";
import Meta from "antd/lib/card/Meta";

type State = {
    loading: boolean;
    companies: Array<CompanyInterface>;
    companiesFiltered: Array<CompanyInterface>;
};

type Props = {
    next: Function;
};

export default class Company extends Component<Props, State> {

    state: State = {
        companies: [] as Array<CompanyInterface>,
        companiesFiltered: [] as Array<CompanyInterface>,
        loading: true
    };

    get renderNorms() {
        const { companies, companiesFiltered } = this.state;
        let list = companiesFiltered.length > 0 ? companiesFiltered : companies;

        return list.map((company: CompanyInterface, key: Number) =>
            <Col key={key.toString()} span={6}>
                <Card
                    hoverable
                    onClick={() => this.props.next({ company })}
                    cover={<img alt="example" width="100%" src={company.logo} />}
                >
                    <Meta title={company.name} />
                </Card>
            </Col>
        );
    }

    getCompanies = async () => this.setState({ companies: await API.get(`/company`), loading: false });

    searchCompany = (filter: String) => {
        const { companies } = this.state;
        const companiesFiltered = companies.filter((company: CompanyInterface) =>
            company.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase())
        );

        this.setState({ companiesFiltered });
    };

    componentDidMount = async () => {
        this.getCompanies();
    };

    render() {
        const { companies, companiesFiltered, loading } = this.state;

        return (
            <Card title="Selecione a empresa">
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <Input.Search
                            placeholder="Digite o nome da empresa"
                            allowClear
                            enterButton
                            size="large"
                            onSearch={this.searchCompany}
                        />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24}>
                        <List
                            loading={loading}
                            style={{ cursor: "pointer" }}
                            itemLayout="horizontal"
                            dataSource={companiesFiltered.length > 0 ? companiesFiltered : companies}
                            renderItem={(company: CompanyInterface) => (
                                <List.Item onClick={() => this.props.next({ company }, company.name)}>
                                    <List.Item.Meta
                                        avatar={<Avatar src={company.logo} />}
                                        title={company.name}
                                        description={`Possui ${company.company_branch.length} unidade(s)`}
                                    />
                                </List.Item>
                            )}
                        />

                    </Col>
                    {/* {this.renderNorms} */}
                </Row>
            </Card>
        );
    }


}
