import React from 'react'
import Form from './Form';
import API from '../../../lib/API';
import { Card, Table, Input, Divider, Button } from 'antd';
import { EditOutlined, EyeOutlined, FormOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CompanyBranchInterface } from '../CompanyBranch/_interfaces';
import { CompanyBranchLocalInterface } from './_interfaces';

type State = {
  companyBranchLocals: Array<CompanyBranchLocalInterface>,
  companyBranchLocal: CompanyBranchLocalInterface,
  isVisible: Boolean,
  companyBranchId: number,
  loading: Boolean
};

type Props = {
  history: any;
  match: any;
}

export default class CompanyBranchLocalIndex extends React.Component<Props, State> {

  state = {
    companyBranchLocal: {} as CompanyBranchLocalInterface,
    companyBranchLocals: [],
    loading: false,
    isVisible: false,
    companyBranchId: 0
  };

  getLocals = async () => this.setState({ companyBranchLocals: await API.get(`/companyBranchLocal/all/${this.state.companyBranchId}`), loading: false });

  hideModalForm = (fetchCompanies: boolean = false) => {
    this.setState({ isVisible: false, companyBranchLocal: {} as CompanyBranchLocalInterface });

    if (fetchCompanies)
      this.setState({ isVisible: false, companyBranchLocal: {} as CompanyBranchLocalInterface, loading: true }, () => this.getLocals());
  };

  componentDidMount = async () => {
    this.setState({ loading: true, companyBranchId: this.props.match.params.companyBranchId }, async () => await this.getLocals());
  };

  render() {
    const { companyBranchId, companyBranchLocals, companyBranchLocal, loading, isVisible } = this.state;

    return (
      <>
        <Button className="right" type="primary" style={{ marginBottom: 20, marginTop: -30 }}
          onClick={() => this.setState({ isVisible: true, companyBranchLocal: {} as CompanyBranchLocalInterface })}>
          Cadastrar
        </Button>
        <Table
          style={{ cursor: "pointer" }}
          loading={loading}
          columns={this.columns}
          dataSource={companyBranchLocals} />
        <Form isVisible={isVisible} hideModalForm={this.hideModalForm} companyBranchLocal={companyBranchLocal} companyBranchId={companyBranchId} />
      </>);
  }


  columns = [
    {
      title: 'Nome da área',
      key: 'name',
      render: (_: any, companyBranchLocal: CompanyBranchLocalInterface) => companyBranchLocal.name
    },
    {
      key: "action",
      render: (_: any, companyBranchLocal: CompanyBranchLocalInterface) => (
        <>
          {/* <EyeOutlined onClick={() => { this.props.history.push(`/company/${companyBranchLocal..companyId}/branch/${companyBranchLocal.id}`) }} /> */}
          {/* <Divider type='vertical' /> */}
          <EditOutlined onClick={() => this.setState({ isVisible: true, companyBranchLocal: companyBranchLocal })} />
        </>
      )
    }
  ];



}
