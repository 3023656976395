import { Col, Row, Steps } from 'antd';
import React, { Component } from 'react';
import { CompanyInterface } from '../Manager/Company/_interfaces';
import { CompanyBranchInterface } from '../Manager/CompanyBranch/_interfaces';
import { CompanyBranchEquipmentInterface } from '../Manager/CompanyBranchEquipment/_interfaces';
import { CompanyBranchLocalInterface } from '../Manager/CompanyBranchLocal/_interfaces';
import { ModuleInterface } from '../_Interfaces/module';
import Content from '../_Layout/Content';
import Company from './Step/Company';
import CompanyBranch from './Step/CompanyBranch';
import CompanyBranchEquipment from './Step/CompanyBranchEquipment';
import CompanyBranchLocal from './Step/CompanyBranchLocal';
import Norm from './Step/Norm';
import StartAssessment from './Step/StartAssessment';

type State = {
    steps: Array<any>;
    current: number;
    module: ModuleInterface;
    company: CompanyInterface;
    companyBranch: CompanyBranchInterface;
    companyBranchLocal: CompanyBranchLocalInterface;
    companyBranchEquipment: CompanyBranchEquipmentInterface;
};

export default class ReportIndex extends Component<any, State> {

    state: State = {
        current: 0,
        module: {} as ModuleInterface,
        company: {} as CompanyInterface,
        companyBranch: {} as CompanyBranchInterface,
        companyBranchLocal: {} as CompanyBranchLocalInterface,
        companyBranchEquipment: {} as CompanyBranchEquipmentInterface,
        steps: [
            {
                title: 'Normas',
                content: Norm,
                status: 'process',
                description: ''
            },
            {
                title: 'Empresa',
                content: Company,
                status: 'wait',
                description: ''
            },
            {
                title: 'Unidade',
                content: CompanyBranch,
                status: 'wait',
                description: ''
            },
            {
                title: 'Área',
                content: CompanyBranchLocal,
                status: 'wait',
                description: ''
            },
            {
                title: 'Equipamento',
                content: CompanyBranchEquipment,
                status: 'wait',
                description: ''
            }
        ],
    }

    nextStep = (model: any, description: string = "") => {
        const { steps, current } = this.state;
        let next = current + 1;
        steps[current].status = "finish";
        steps[current].description = description;

        if (steps[next])
            steps[next].status = "process";

        this.setState({ ...model, current: next, steps });
    };

    render(): React.ReactNode {
        const { current, steps } = this.state;

        let StepContent = null;

        if (steps[current])
            StepContent = steps[current].content;

        return (

            steps.length === current
                ? <StartAssessment {...this.state} />
                : <Content crumbs={[{ name: "Laudos" }]} >
                    <Row>
                        <Col span={4}>
                            <Steps
                                style={{ height: "50vh" }}
                                direction="vertical"
                                current={current}>
                                {steps.map((item, key) => (
                                    <Steps.Step
                                        status={item.status}
                                        key={key}
                                        title={item.title}
                                        description={item.description}
                                    />
                                ))}
                            </Steps>
                        </Col>
                        <Col span={20}>
                            <div className="steps-content">
                                <StepContent next={this.nextStep} {...this.state} />
                            </div>
                        </Col>

                    </Row>
                </Content>
        );
    }
};