import React, { Component } from "react";
import { InstrumentStandardInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col, Select, Space, Button, Divider, Upload, message } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";
import { PlusCircleOutlined, PlusOutlined, MinusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import FormInstrument from './Instrument/Form';
import { InstrumentInterface } from "./Instrument/_interfaces";
import moment from "moment";

type State = {
    saveLoading: boolean;
    newInstrument: boolean;
    uploadingFiles: boolean;
    instruments: Array<InstrumentInterface>;
};

type Props = {
    instrumentStandard: InstrumentStandardInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
        newInstrument: false,
        uploadingFiles: false,
        instruments: []
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 12
    };

    hideModalForm = (fetchInstrumentsStandard: boolean = false) => {
        this.setState({ newInstrument: false });

        if (fetchInstrumentsStandard)
            this.setState({ newInstrument: false }, () => this.getInstruments());
    };

    onCancel = () => this.props.hideModalForm();

    getInstruments = async () => this.setState({ instruments: await API.get('/instrument') });

    saveItem = async (instrumentStandard: InstrumentStandardInterface) => {
        this.setState({ saveLoading: true });

        if (this.props.instrumentStandard.id)
            await API.put(`/instrumentStandard/${this.props.instrumentStandard.id}`, instrumentStandard);
        else
            await API.post('/instrumentStandard', instrumentStandard);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    }

    componentDidMount = () => this.getInstruments();

    render() {
        const { isVisible, instrumentStandard } = this.props;
        const { saveLoading, newInstrument, instruments, uploadingFiles } = this.state;

        return (
            <Modal
                width={1000}
                centered
                title={instrumentStandard.id ? "Editar padrão de calibração" : "Novo padrão de calibração"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
                okButtonProps={{ disabled: uploadingFiles }}
            >
                <Form ref={this.formRef} layout="vertical" initialValues={instrumentStandard} onFinish={this.onOk}>
                    <label><b>Informações do instrumento</b></label>
                    <Row>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="instrumentId"
                                rules={[{ required: true, message: "Selecione uma categoria" }]}
                                label={<span>Instrumento <PlusCircleOutlined onClick={() => this.setState({ newInstrument: true })} /> </span>}>
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {instruments.map((instrument, key) => <Select.Option key={key} value={instrument.id}>{instrument.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="tag" label="TAG" rules={[{ required: true, message: "Digite a TAG" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="scale" label="Escala" rules={[{ required: true, message: "Digite a escala" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <label><b>Histórico de calibrações</b></label>
                    <Form.List name="calibrations">
                        {(calibrations, { add, remove }) => (
                            <>
                                {calibrations.map(({ key, name, ...restField }) => (
                                    <Row key={key}>
                                        <Col lg={8}>
                                            <Form.Item
                                                initialValue={moment().format('yyyy-MM-DD')}
                                                label="Data da calibração"
                                                name={[name, 'calibrationDate']}
                                                fieldKey={[key, 'calibrationDate']}
                                                rules={[{ required: true, message: 'Insira a data da calibração' }]}
                                            >
                                                <Input type="date" placeholder="Selecione uma data" />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Item
                                                initialValue={moment().format('yyyy-MM-DD')}
                                                label="Data de vencimento"
                                                name={[name, 'calibrationDueDate']}
                                                fieldKey={[key, 'calibrationDueDate']}
                                                rules={[{ required: true, message: 'Insira a data de vencimento' }]}
                                            >
                                                <Input type="date" placeholder="Selecione uma data" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                label="Certificado da calibração"
                                                name={[name, 'certificate']}
                                                fieldKey={[key, 'certificate']}
                                                rules={[{ required: true, message: 'Insira o certificado' }]}
                                            >
                                                <Upload
                                                    id={name.toString() + key.toString()}
                                                    multiple={false}
                                                    action={`${API.api_url}/file/upload/_certifications`}
                                                    onChange={(info: any) => {
                                                        if (info.file.status !== 'uploading') {
                                                            this.setState({ uploadingFiles: true });
                                                        }
                                                        if (info.file.status === 'done') {
                                                            this.setState({ uploadingFiles: false });
                                                        } else if (info.file.status === 'error') {
                                                            message.error(`Falha ao realizar o upload do arquivo`);
                                                        }
                                                    }}
                                                >
                                                    <Button icon={<UploadOutlined />}>Selecione o arquivo</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <MinusCircleOutlined style={{ paddingTop: 40 }} onClick={() => remove(name)} />
                                    </Row>
                                ))}
                                <Row>
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Adicionar calibração
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </Form>
                <FormInstrument isVisible={newInstrument} hideModalForm={this.hideModalForm} />
            </Modal >
        );
    }
}

export default NewItem;
