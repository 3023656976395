import React, { Component } from "react";
import { CompanyInterface } from "./_interfaces";
import { Form, Input, Modal, Upload } from "antd";
import { FormInstance } from "antd/lib/form";
import ImgCrop from 'antd-img-crop';
import API from "../../../lib/API";

type State = {
    saveLoading: boolean;
    fileList: any;
};

type Props = {
    company: CompanyInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
        fileList: [],
    };

    private formRef = React.createRef<FormInstance>();

    saveItem = async (company: CompanyInterface) => {
        this.setState({ saveLoading: true });

        if (this.props.company.id)
            await API.put(`/company/${this.props.company.id}`, company);
        else
            await API.post('/company', company);

        this.setState({ saveLoading: false, fileList: [] }, () => this.props.hideModalForm(true));
    };

    onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
    };

    onChange = (file: any) => {
        if (file.file.status === 'done')
            this.formRef.current?.setFieldsValue({ logo: file.file.response });

        this.setState({ fileList: file.fileList })
    };

    onCancel = () => this.props.hideModalForm();
    onRemove = () => this.formRef.current?.setFieldsValue({ logo: null });

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    }

    componentWillReceiveProps = (nextProps: Props) => {
        const { company } = nextProps;

        if (company.id) {
            this.setState({
                fileList: [
                    {
                        uid: company.id,
                        name: company.logo,
                        url: company.logo,
                        status: "done"
                    }
                ]
            })
        } else
            this.setState({ fileList: [] })

    }

    render() {
        const { isVisible, company } = this.props;
        const { fileList, saveLoading } = this.state;

        return (
            <Modal
                centered
                title={company.id ? "Editar empresa" : "Nova empresa"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" initialValues={company} onFinish={this.onOk}>
                    <div style={{ textAlign: 'center' }}>
                        <ImgCrop rotate>
                            <Upload
                                action={`${API.api_url}/file/upload/_companies`}
                                listType="picture-card"
                                fileList={fileList}
                                onChange={this.onChange}
                                onRemove={this.onRemove}
                                onPreview={this.onPreview}
                                multiple={false}
                                maxCount={1}
                            >
                                Logo
                            </Upload>
                        </ImgCrop>
                    </div>
                    <Form.Item name="logo" rules={[{ required: true, message: "Selecione um logo" }]}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Digite o nome da empresa" }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default NewItem;
