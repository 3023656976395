import React, { Component } from "react";
import { EquipmentInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col, Select, Space, Button, Divider, Upload, message } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";
import { PlusCircleOutlined } from "@ant-design/icons";
import FormReport from './Report/Form';
import { NormInterface } from "../Norm/_interfaces";
import { InstrumentStandardInterface } from "../InstrumentStandard/_interfaces";
import { ReportInterface } from "./Report/_interfaces";

type State = {
    reports: Array<ReportInterface>;
    norms: Array<NormInterface>;
    instrumentsStandard: Array<InstrumentStandardInterface>;
    saveLoading: boolean;
    newReport: boolean;
    uploadingFiles: boolean;
};

type Props = {
    equipment: EquipmentInterface;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        reports: [],
        norms: [],
        instrumentsStandard: [],
        saveLoading: false,
        newReport: false,
        uploadingFiles: false,
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 12
    };

    hideModalForm = () => this.setState({ newReport: false }, () => this.fetcOptions());
    onCancel = () => this.props.hideModalForm();

    fetcOptions = async () => {
        this.setState({
            norms: await API.get('/norm'),
            instrumentsStandard: await API.get('/instrumentStandard'),
            reports: await API.get(`/report/${this.props.equipment.id}`)
        })
    };

    saveItem = async (equipment: EquipmentInterface) => {
        this.setState({ saveLoading: true });

        if (this.props.equipment.id)
            await API.put(`/equipment/${this.props.equipment.id}`, equipment);
        else
            await API.post('/equipment', equipment);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    }

    componentDidMount = async () => this.fetcOptions();
    componentWillReceiveProps = async () => this.fetcOptions();

    render() {
        const { isVisible, equipment } = this.props;
        const { norms, instrumentsStandard, reports, saveLoading, newReport, uploadingFiles } = this.state;

        return (
            <Modal
                width={1000}
                centered
                title={equipment.id ? "Editar equipamento" : "Novo equipamento"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
                okButtonProps={{ disabled: uploadingFiles }}
            >
                <Form ref={this.formRef} layout="vertical" initialValues={equipment} onFinish={this.onOk}>
                    <Row>
                        <Col lg={12} {...this.propsForSmallScreen}>
                            <Form.Item name="name" label="Denominação" rules={[{ required: true, message: "Digite a denominação" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} {...this.propsForSmallScreen}>
                            <Form.Item name="initials" label="Sigla" rules={[{ required: true, message: "Digite a sigla" }]} tooltip="A sigla será utilizada para a padronização no momento de gerar os relatórios de inspeção.">
                                <Input />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={12} {...this.propsForSmallScreen}>
                            <Form.Item name="instrumentStandardId" label="Padrões de Calibração" rules={[{ required: true, message: "Selecione uma norma" }]}
                                initialValue={equipment.equipment_instrument_standard ? equipment.equipment_instrument_standard.map((instrumentStandard: InstrumentStandardInterface) => instrumentStandard.id) : []}>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {instrumentsStandard.map((instrumentStandard, key) => <Select.Option key={key} value={instrumentStandard.id}>{instrumentStandard.instrument.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} {...this.propsForSmallScreen}>
                            <Form.Item name="normId" label="Normas e Procedimentos" rules={[{ required: true, message: "Selecione uma norma" }]}
                                initialValue={equipment.equipment_norm ? equipment.equipment_norm.map((norm: NormInterface) => norm.id) : []}>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {norms.map((norm, key) => <Select.Option key={key} value={norm.id}>{norm.number}/{norm.revision}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="reportId"
                                rules={[{ required: true, message: "Selecione uma relatório" }]}
                                label={<span>Relatório <PlusCircleOutlined onClick={() => this.setState({ newReport: true })} /> </span>}>
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {reports.map((report, key) => <Select.Option key={key} value={report.id}>{report.title}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <FormReport isVisible={newReport} hideModalForm={this.hideModalForm} companyBranchEquipmentId={equipment.id} />
            </Modal >
        );
    }
}

export default NewItem;
