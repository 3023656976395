import React from 'react'
import Form from './Form';
import API from '../../../lib/API';
import { Table, Row, Col, Button } from 'antd';
import { AssessmentInterface } from './_interfaces';
import { EditOutlined, FormOutlined, ReadOutlined } from "@ant-design/icons";

type State = {
    companyBranchEquipmentId: number,
    assessments: Array<AssessmentInterface>,
    assessment: AssessmentInterface | {},
    isVisible: Boolean,
    loading: Boolean
};

type Props = {
    history: any;
    match: any;
}

export default class ManagerAssessmentIndex extends React.Component<Props, State> {

    state = {
        assessment: {},
        assessments: [],
        loading: false,
        isVisible: false,
        companyBranchEquipmentId: 0
    };

    getAssessments = async () => this.setState({ assessments: await API.get(`/assessment/${this.state.companyBranchEquipmentId}`), loading: false });

    hideModalForm = (fetchCompanies: boolean = false) => {
        this.setState({ isVisible: false, assessment: {} });

        if (fetchCompanies)
            this.setState({ isVisible: false, assessment: {}, loading: true }, () => this.getAssessments());
    };

    onDelete = async (assessment: AssessmentInterface) => {
        this.setState({ loading: true });
        await API.delete(`/assessment/${assessment.id}`);
        this.getAssessments();
    };

    componentDidMount = async () => {
        this.setState({ loading: true, companyBranchEquipmentId: this.props.match.params.companyBranchEquipmentId }, async () => await this.getAssessments());
    };

    componentWillReceiveProps = async (nextProps: Props) => {
        console.log(nextProps)
        this.setState({ loading: true, companyBranchEquipmentId: nextProps.match.params.companyBranchEquipmentId }, async () => await this.getAssessments());
    };

    render() {
        const { companyBranchEquipmentId, assessments, assessment, loading, isVisible } = this.state;

        return (
            <>
                <Button className="right" type="primary" style={{ marginBottom: 16 }}
                    onClick={() => this.setState({ isVisible: true, assessment: {} })}>
                    Cadastrar
                </Button>
                <Table
                    style={{ cursor: "pointer" }}
                    loading={loading}
                    columns={this.columns}
                    dataSource={assessments}
                    onRow={(assessment: AssessmentInterface) => {
                        return {
                            onClick: () => window.location.href = `/assessment/${assessment.id}`
                        };
                    }} />
                <Form isVisible={isVisible} hideModalForm={this.hideModalForm} assessment={assessment} companyBranchEquipmentId={companyBranchEquipmentId} />
            </>);
    }

    columns = [
        {
            title: 'Avaliação',
            key: 'name',
            render: (_: any, assessment: AssessmentInterface) => (
                <Row>
                    <Col style={{ padding: 10 }}><ReadOutlined style={{ fontSize: '200%' }} /></Col>
                    <Col>
                        {assessment.company_branch_equipment.equipment.name}<br />
                        {assessment.id} - {assessment.orderOfService}
                    </Col>
                </Row>)
        },
        {
            // title: <FormOutlined style={{ fontSize: '120%' }} onClick={() => this.setState({ isVisible: true, assessment: {} })} />,
            key: "action",
            width: '10%',
            render: (_: any) => (
                <EditOutlined />
            )
        }
    ];
}