import React from 'react'
import { Button, Form, Input, message } from "antd"
import Api from "../../lib/API";
import { RouteComponentProps } from "react-router-dom"
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { translate as t } from "../../translator/translate";
import Authentication from "../../lib/Authentication";

export default class Login extends React.Component<RouteComponentProps> {
    state = {
        loading: false
    };

    render() {
        return (
            <div className="screen-container">
                <div className="form-container">
                    <Form name="login" layout="vertical" onFinish={this.handleSubmit}>
                        <h1 style={{ textAlign: "center" }}>SAGA</h1>

                        <br />

                        <Form.Item name="email" rules={[{ required: true, type: "email", message: "Por favor, digite seu e-mail" }]}>
                            <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("Insira o seu e-mail")} />
                        </Form.Item>

                        <Form.Item name="password" rules={[{ required: true, message: "Por favor, digite sua senha" }]}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Insira a sua senha")} />
                        </Form.Item>

                        <hr />

                        <div className="flex align-center">
                            {/* <Link to="/login/" className="fs-20">{t("Esqueceu sua senha?")}</Link> */}

                        </div>
                        <div className="flex align-center space-between">
                            <Button loading={this.state.loading} type="primary" htmlType="submit">{t("LOGIN")}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });

        const res = await Api.post("/auth/login", values);

        console.log(res)

        this.setState({ loading: false });

        if (!res)
            return message.warning('Verifique seu usuário e/ou senha')

        Authentication.login(res.jwt, res.user);
        this.props.history.push('/dashboard');
    }
}
