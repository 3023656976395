import React from 'react'
import Breadcrumb from '../../lib/components/Breadcrumb';

type Props = {
    crumbs: Array<any>
};

export default class Content extends React.Component<Props> {

    render() {
        return <>
            <Breadcrumb crumbs={this.props.crumbs} />
            <div className="site-layout" style={{ padding: 0, minHeight: 600 }}>
                {this.props.children}
            </div>
        </>
    }
}