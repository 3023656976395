import React from 'react'
import API from '../../../lib/API';
import { Row, Col } from 'antd';
import Content from '../../_Layout/Content';
import { CompanyBranchEquipmentInterface } from './_interfaces';
import ManagerAssessmentIndex from '../Assessment/_Index';

type State = {
    companyBranchEquipment: CompanyBranchEquipmentInterface;
    loading: Boolean;
};

type Props = {
    history: any;
    match: any;
}

export default class CompanyBranchEquipmentView extends React.Component<Props, State> {

    state = {
        companyBranchEquipment: {} as CompanyBranchEquipmentInterface,
        loading: true,
    };

    getCompany = async () => this.setState({
        companyBranchEquipment: await API.get(`/companyBranchEquipment/${this.props.match.params.companyBranchEquipmentId}`),
        loading: false
    });

    componentDidMount = async () => {
        this.getCompany();
    };

    crumbs = () => {
        const { companyBranchEquipment } = this.state;
        return companyBranchEquipment.id
            ? [
                // { name: companyBranchEquipment.company_branch.company.name },
                { name: "Unidades" },
                // {
                //     name: companyBranchEquipment.company_branch.observation != ""
                //         ? companyBranchEquipment.company_branch.city.name + " - " + companyBranchEquipment.company_branch.observation
                //         : companyBranchEquipment.company_branch.city.name
                // },
                { name: companyBranchEquipment.equipment.name }
            ]
            : [{ name: "Carregando..." }];
    }

    render() {
        return (
            <Content crumbs={this.crumbs()} >
                <Row>
                    <Col span={24}>
                        <ManagerAssessmentIndex {...this.props} />
                    </Col>
                </Row>
            </Content>);
    }

}