import React from 'react'
import Form from './Form';
import API from '../../../lib/API';
import { Table, Divider, Button } from 'antd';
import { CompanyBranchInterface } from './_interfaces';
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

type State = {
  companyBranchies: Array<CompanyBranchInterface>,
  companyBranch: CompanyBranchInterface | {},
  isVisible: Boolean,
  companyId: number,
  loading: Boolean
};

type Props = {
  history: any;
  match: any;
}

export default class CompanyBranchIndex extends React.Component<Props, State> {

  state = {
    companyBranch: {},
    companyBranchies: [],
    loading: false,
    isVisible: false,
    companyId: 0
  };

  getCompanies = async () => this.setState({ companyBranchies: await API.get(`/companyBranch/all/${this.state.companyId}`), loading: false });

  hideModalForm = (fetchCompanies: boolean = false) => {
    this.setState({ isVisible: false, companyBranch: {} });

    if (fetchCompanies)
      this.setState({ isVisible: false, companyBranch: {}, loading: true }, () => this.getCompanies());
  };

  componentDidMount = async () => {
    this.setState({ loading: true, companyId: this.props.match.params.companyId }, async () => await this.getCompanies());
  };

  render() {
    const { companyId, companyBranchies, companyBranch, loading, isVisible } = this.state;

    return (
      <>
        <Button className="right" type="primary" style={{ marginBottom: 20, marginTop: -30 }}
          onClick={() => this.setState({ isVisible: true, companyBranch: {} })}>
          Cadastrar
        </Button>
        <Table
          style={{ cursor: "pointer" }}
          loading={loading}
          columns={this.columns}
          dataSource={companyBranchies} />
        <Form isVisible={isVisible} hideModalForm={this.hideModalForm} companyBranch={companyBranch} companyId={companyId} />
      </>);
  }


  columns = [
    {
      title: 'Nome da unidade',
      key: 'name',
      render: (_: any, companyBranch: CompanyBranchInterface) => (
        <a href={`/company/${this.state.companyId}/branch/${companyBranch.id}`} style={{ color: "black" }}>
          {companyBranch.observation != "" ? companyBranch.city.name + " - " + companyBranch.observation : companyBranch.city.name}
        </a>
      )
    },
    {
      key: "action",
      render: (_: any, companyBranch: CompanyBranchInterface) => (
        <>
          <EyeOutlined onClick={() => { this.props.history.push(`/company/${companyBranch.companyId}/branch/${companyBranch.id}`) }} />
          <Divider type='vertical' />
          <EditOutlined onClick={() => this.setState({ isVisible: true, companyBranch: companyBranch })} />
        </>
      )
    }
  ];



}
