import React from 'react'
import Form from './Form';
import API from '../../../lib/API';
import { Table, Divider, Button } from 'antd';
import { InstrumentStandardInterface } from './_interfaces';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Popconfirm from 'antd/es/popconfirm';
import Content from '../../_Layout/Content';

type Props = {};

type State = {
    instrumentsStandard: Array<InstrumentStandardInterface>,
    filteredInstrumentsStandard: Array<InstrumentStandardInterface>,
    instrumentStandard: InstrumentStandardInterface | {},
    isVisible: Boolean,
    loading: Boolean
};
export default class InstrumentStandardIndex extends React.Component<Props, State> {

    state = {
        instrumentStandard: {},
        instrumentsStandard: [],
        filteredInstrumentsStandard: [],
        loading: false,
        isVisible: false
    };

    getInstrumentsStandard = async () => this.setState({ instrumentsStandard: await API.get('/instrumentStandard'), loading: false });

    hideModalForm = (fetchInstrumentsStandard: boolean = false) => {
        this.setState({ isVisible: false, instrumentStandard: {} });

        if (fetchInstrumentsStandard)
            this.setState({ isVisible: false, instrumentStandard: {}, loading: true }, () => this.getInstrumentsStandard());
    };

    onDelete = async (instrumentStandard: InstrumentStandardInterface) => {
        this.setState({ loading: true });
        await API.delete(`/instrumentStandard/${instrumentStandard.id}`);
        this.getInstrumentsStandard();
    };

    componentDidMount = async () => {
        this.setState({ loading: true });
        await this.getInstrumentsStandard();
    };

    render() {
        const { instrumentsStandard, filteredInstrumentsStandard, instrumentStandard, loading, isVisible } = this.state;

        return (
            <Content crumbs={[{ name: "Padrões de Calibração" }]}>
                <Button className="right" type="primary" style={{ marginBottom: 16 }}
                    onClick={() => this.setState({ isVisible: true, instrumentStandard: {} })}>
                    Cadastrar
                </Button>
                <Table
                    style={{ cursor: "pointer" }}
                    loading={loading}
                    columns={this.columns}
                    dataSource={filteredInstrumentsStandard.length > 0 ? filteredInstrumentsStandard : instrumentsStandard}
                />
                <Form isVisible={isVisible} hideModalForm={this.hideModalForm} instrumentStandard={instrumentStandard} />
            </Content>);
    }

    columns = [
        {
            title: "Instrumento",
            dataIndex: 'instrument',
            key: 'name',
            render: (_: any, instrumentsStandard: any) => instrumentsStandard.instrument.name,

        },
        {
            title: "TAG",
            dataIndex: 'tag',
            key: 'tag',

        },
        {
            title: "Escala",
            dataIndex: 'scale',
            key: 'scale',

        },
        {
            key: "action",
            render: (_: any, instrumentStandard: InstrumentStandardInterface) => (
                <>
                    <EditOutlined onClick={() => this.setState({ isVisible: true, instrumentStandard: instrumentStandard })} />
                    <Divider type="vertical" />
                    <Popconfirm title={"Deseja remover?"}
                        placement="top"
                        onConfirm={() => this.onDelete(instrumentStandard)}
                        cancelText={"Cancelar"}
                        okText={"Deletar"}
                        okType={"danger"}>
                        <DeleteOutlined />
                    </Popconfirm>
                </>)
        }
    ];

}