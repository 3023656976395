import React, { Component } from "react";
import { NormInterface } from "./_interfaces";
import { Form, Input, Modal, Row, Col, Select, Space, Button, Divider, Upload, Switch, message } from "antd";
import { FormInstance } from "antd/lib/form";
import API from "../../../lib/API";
import { UploadOutlined } from "@ant-design/icons";
import FormInstrument from '../InstrumentStandard/Instrument/Form';

type State = {
    saveLoading: boolean;
    uploadingFiles: boolean;
};

type Props = {
    norm: NormInterface | any;
    isVisible: boolean;
    hideModalForm: Function;
};

class NewItem extends Component<Props, State> {
    state: State = {
        saveLoading: false,
        uploadingFiles: false,
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 12
    };

    onCancel = () => this.props.hideModalForm();

    saveItem = async (norm: NormInterface) => {
        this.setState({ saveLoading: true });

        if (this.props.norm.id)
            await API.put(`/norm/${this.props.norm.id}`, norm);
        else
            await API.post('/norm', norm);

        this.setState({ saveLoading: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    }

    render() {
        const { isVisible, norm } = this.props;
        const { saveLoading, uploadingFiles } = this.state;

        return (
            <Modal
                width={1000}
                centered
                title={norm.id ? "Editar norma ou procedimento" : "Nova norma ou procedimento"}
                destroyOnClose={true}
                visible={isVisible}
                confirmLoading={saveLoading}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
                okButtonProps={{ disabled: uploadingFiles }}
            >
                <Form ref={this.formRef} layout="vertical" initialValues={norm} onFinish={this.onOk}>
                    <Row>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="name" label="Denominação" rules={[{ required: true, message: "Digite a denominação" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="number" label="Número" rules={[{ required: true, message: "Digite o número" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="revision" label="Revisão" rules={[{ required: true, message: "Digite a revisão" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item name="visibleToCustomer" label="Disponibilizar ao cliente?" valuePropName="checked" initialValue={norm.visibleToCustomer}>
                                <Switch checkedChildren="Sim" unCheckedChildren="Não" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                label="Arquivo"
                                name="procedureFile"
                            >
                                <Upload
                                    multiple={false}
                                    action={`${API.api_url}/file/upload/_certifications`}
                                    onChange={(info: any) => {
                                        if (info.file.status !== 'uploading') {
                                            this.setState({ uploadingFiles: true });
                                        }
                                        if (info.file.status === 'done') {
                                            this.setState({ uploadingFiles: false });
                                        } else if (info.file.status === 'error') {
                                            message.error(`Falha ao realizar o upload do arquivo`);
                                        }
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>Selecione o arquivo</Button>
                                </Upload>
                            </Form.Item></Col>
                    </Row>
                </Form>
            </Modal >
        );
    }
}

export default NewItem;
