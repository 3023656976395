import React, { Component } from "react";
import { Button, Col, message, Result, Row, Typography } from 'antd';
import { CompanyInterface } from "../../Manager/Company/_interfaces";
import { CompanyBranchInterface } from "../../Manager/CompanyBranch/_interfaces";
import { CompanyBranchLocalInterface } from "../../Manager/CompanyBranchLocal/_interfaces";
import { CompanyBranchEquipmentInterface } from "../../Manager/CompanyBranchEquipment/_interfaces";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

type State = {
    loading: boolean;
};

type Props = {
    company: CompanyInterface;
    companyBranch: CompanyBranchInterface;
    companyBranchLocal: CompanyBranchLocalInterface;
    companyBranchEquipment: CompanyBranchEquipmentInterface;
};

export default class StartAssessment extends Component<Props, State> {

    renderCompanyBranchName = (companyBranch: CompanyBranchInterface) =>
        companyBranch.observation != "" ? companyBranch.city.name + " - " + companyBranch.observation : companyBranch.city.name;


    render() {
        const { company, companyBranch, companyBranchLocal, companyBranchEquipment } = this.props;

        return (
            <Row style={{ justifyContent: "center" }}>
                <Col span={12}>
                    <Result
                        status="success"
                        title="Tudo pronto"
                        subTitle="Clique no botão abaixo para iniciar sua inspeção"
                    >
                        <div style={{ textAlign: "center" }}>
                            <Typography.Paragraph>
                                <Typography.Text strong style={{ fontSize: 16, }}>
                                    Configurações iniciais:
                                </Typography.Text>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <CheckCircleFilled /> {company.name}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <CheckCircleFilled /> {this.renderCompanyBranchName(companyBranch)}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <CheckCircleFilled /> {companyBranchLocal.name}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <CheckCircleFilled /> {companyBranchEquipment.equipment.name}
                            </Typography.Paragraph>
                            <Button type="primary" key="console" onClick={() => {
                                message.warning("CALMA CARLINHOS");
                            } }>INICIAR INSPEÇÃO</Button>
                        </div>
                    </Result>
                </Col>
            </Row>
        );
    }


}
